<template>
  <!-- Modal -->
  <div
    id="modal-layout"
    class="modal fade"
    :class="position"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
</template>

<script>
import { onMounted, onUpdated, reactive } from "vue";
import { Modal } from "bootstrap";
export default {
  name: "ModalLayout",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    position: {
      type: String,
      default: "center",
    },
  },
  emits: ["update:showModal"],
  setup(props, { emit }) {
    const state = reactive({
      myBSModalState: "",
    });

    onMounted(() => {
      const myModalEl = document.getElementById("modal-layout");
      state.myBSModalState = new Modal(myModalEl);
      myModalEl.addEventListener("hidden.bs.modal", function () {
        emit("update:showModal", false);
      });
    });
    onUpdated(() => {
      props.showModal
        ? state.myBSModalState.show()
        : state.myBSModalState.hide();
    });
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  height: 100%;
  margin: auto;
  position: fixed;
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.center .modal-body,
.modal.left .modal-body,
.modal.right .modal-body {
  background-color: $st-numbus;
  padding: 0;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: 0;
  transition: opacity 0.3s linear, left 0.3s ease-;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0;
  transition: opacity 0.3s linear, right 0.3s ease-;
}

/*Center*/
.modal.center.fade .modal-dialog {
  top: 15%;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border: 0;
  border-radius: 0;
}

.modal-header {
  align-items: flex-start;
  background-color: $white;
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
}

.modal-header > h5 {
  font-weight: bold;
}

@media screen and (min-width: 576px) {
  .modal.right .modal-dialog {
    min-width: 476px;
  }
}
</style>
