<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ commitData.msg }}</h5>
    </div>
    <div class="container card-footer">
      <div class="row">
        <div class="col-md-8 align-self-center">
          Author: {{ commitData.author }}
        </div>

        <div class="col-md-4 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-dark sha-box"
            @click="doCopy(commitData.id)"
            @keypress="doCopy(commitData.id)"
          >
            {{ state.commitID }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copyText } from "vue3-clipboard";
import { reactive } from "vue";
export default {
  name: "ChangelogCard",
  props: {
    commitData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      commitID: props.commitData.id.slice(0, 7),
    });
    const doCopy = (str) => {
      copyText(str, document.getElementsByClassName("modal")[0], (error) => {
        if (error) {
          state.commitID = "Some error occured";
        } else {
          state.commitID = "Copied";
        }
        setTimeout(function () {
          state.commitID = props.commitData.id.slice(0, 7);
        }, 2000);
      });
    };

    return { doCopy, state };
  },
};
</script>
<style lang="scss" scoped>
img {
  border-radius: 50%;
}

.card {
  font-size: 14px;
  padding: 10px;
  z-index: 5;

  .card-title {
    font-size: 16px;
    font-weight: bold;
  }

  .card-footer {
    background-color: $white;
    padding: 15px;
  }

  .sha-box {
    font-size: 14px;
    font-weight: bold;
    height: 38px;
    width: auto;
  }
}

@media screen and (max-width: 576px) {
  .sha-box {
    margin-top: 10px;
  }
}
</style>
