<template>
  <Spinner v-if="loading" class="centered" />
  <div v-else-if="error">Error: {{ error.message }}</div>
  <div v-else-if="policy" class="st-view-wrapper">
    <div class="st-view-header-wrapper">
      <div class="st-view-header-content">
        <div class="d-flex justify-content-between">
          <div>
            <router-link
              id="policies"
              class="button breadcrumbs-item"
              to="/policies"
            >
              Policies
            </router-link>
            <span class="breadcrumbs-item">&lt;</span>
            <a class="disabled">
              {{ policy.name }}
            </a>
          </div>
          <button
            type="button"
            class="btn btn-dark changelog-button"
            @click="modalToggle"
            @keypress="modalToggle"
          >
            <span class="st-icon st-icon-changelog"></span>
            Changelog
          </button>
        </div>
        <div class="st-metadata-wrapper">
          <div class="text-muted st-header-title-tag">POLICY DETAILS</div>
          <h5 class="st-header-title">{{ policy.name }}</h5>
          <div class="container-fluid st-metadata-body-wrapper">
            <div class="row justify-content-between">
              <div class="col-md-6 col-sm-12 st-meta-data-item-wrapper">
                <div>
                  <div class="st-meta-data-title-tag">Description</div>
                  <div class="st-meta-data-main">
                    {{ policy.description }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 st-meta-data-item-wrapper">
                <div>
                  <div class="st-meta-data-title-tag">
                    Latest Policy Modified
                  </div>
                  <div class="st-meta-data-main" data-testid="last-modified">
                    {{ lastModifiedDate }} by {{ latestCommit.author }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="st-view-body-wrapper">
      <div class="st-view-body-content">
        <div class="policy-metric-body">
          <div class="st-tab-selection time-duration">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  id="one_day"
                  role="button"
                  class="st-tab-link"
                  :class="{ active: state.duration === TimePeriod.daily }"
                  tabindex="0"
                  @click="updateData(TimePeriod.daily)"
                  @keypress="updateData(TimePeriod.daily)"
                  >1D</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="one_week"
                  role="button"
                  class="st-tab-link"
                  :class="{ active: state.duration === TimePeriod.weekly }"
                  tabindex="0"
                  @click="updateData(TimePeriod.weekly)"
                  @keypress="updateData(TimePeriod.weekly)"
                  >1W</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="one_month"
                  role="button"
                  class="st-tab-link"
                  :class="{ active: state.duration === TimePeriod.monthly }"
                  tabindex="0"
                  @click="updateData(TimePeriod.monthly)"
                  @keypress="updateData(TimePeriod.monthly)"
                  >1M</a
                >
              </li>
            </ul>
          </div>
          <div class="metrics-wrapper container-fluid">
            <div v-if="loadingChart"><Spinner /></div>
            <div v-else-if="errorChart" class="text-danger">
              Error fetching metrics
            </div>
            <div v-else class="row">
              <AreaChartWithDetail
                class="col-xxl-4 col-lg-6 col-sm-12"
                title="Resource Count"
                :metrics-api-data="policyResult.metricResources.values"
                :from-date="from"
              />
              <AreaChartWithDetail
                class="col-xxl-4 col-lg-6 col-sm-12"
                title="Execution Time"
                :metrics-api-data="policyResult.metricDuration.values"
                :from-date="from"
              />
              <AreaChartWithDetail
                class="col-xxl-4 col-lg-6 col-sm-12"
                title="API Calls"
                :metrics-api-data="policyResult.metricApiCalls.values"
                :from-date="from"
              />
            </div>
          </div>
        </div>
        <div class="st-tab-selection">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="st-tab-link"
                :class="{ active: state.tableViewPolicy === 'resourceView' }"
                role="button"
                tabindex="0"
                @click="resourceView"
                @keypress="resourceView"
                >Resources</a
              >
            </li>
            <li class="nav-item">
              <a
                class="st-tab-link"
                :class="{ active: state.tableViewPolicy === 'executionView' }"
                role="button"
                tabindex="0"
                @click="executionView"
                @keypress="executionView"
                >Executions</a
              >
            </li>
          </ul>
        </div>
        <div v-if="state.tableViewPolicy === 'resourceView'">
          <ResourceTable
            :resource-type="resourceType"
            :policy-id="policy.uuid"
            gql-query-key="ResourceTable"
          />
        </div>
        <div v-else>
          <ExecutionList :query="state.searchQuery" :policy-id="policy.uuid" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    We can not find policy name {{ policyName }}. Please check your policy name
    on the route if you have it correctly.
  </div>

  <!-- Modal -->
  <div class="changelog-wrapper">
    <ModalLayout v-model:showModal="state.showModal" position="right">
      <template #header class="modal-header-slot">
        <a
          class="text-dark close back-button"
          role="button"
          aria-label="Close"
          tabindex="0"
          @click="modalToggle"
          @keypress="modalToggle"
        >
          &lt; Back
        </a>
        <h5 class="changelog">Changelog</h5>
      </template>
      <template #default>
        <div v-if="loading">Loading...</div>
        <div v-else-if="error">Error: {{ error.message }}</div>
        <ChangelogDateGroup
          v-for="(commits, dateKey) in dateGroupCommits"
          v-else-if="dateGroupCommits"
          :key="dateKey"
          :date-group="dateKey"
          :commits-data="commits.map((commit) => commit.commit)"
        />
      </template>
    </ModalLayout>
  </div>
  <!---->
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";

import AreaChartWithDetail from "@/components/AreaChartWithDetail";
import ExecutionList from "@/components/ExecutionList";
import ResourceTable from "@/components/ResourceTable";
import Spinner from "@/components/Spinner";
import ChangelogDateGroup from "@/components/ChangelogDateGroup.vue";
import ModalLayout from "@/components/ModalLayout.vue";
import { usePolicyMetrics } from "@/composables/usePolicyMetrics";
import policyQuery from "@/graphql/policy.query.gql";
import {
  getFirstDateByPeriod,
  getIsoUtcDateTimeFormatted,
  getFormatDate,
} from "@/utils/datetime.js";
import { TimePeriod } from "@/utils/enum.js";

export default {
  name: "PolicyDetail",
  components: {
    ExecutionList,
    AreaChartWithDetail,
    ResourceTable,
    Spinner,
    ChangelogDateGroup,
    ModalLayout,
  },
  setup() {
    const route = useRoute();
    const policyName = computed(() => route.params.policyName);

    const tableViewPolicy =
      localStorage.getItem("tableViewPolicy") || "resourceView";
    const state = reactive({
      tableViewPolicy,
      showModal: false,
      searchQuery: "",
      duration: TimePeriod.monthly,
    });

    const { result, loading, error } = useQuery(policyQuery, {
      name: policyName,
    });
    const policy = useResult(result, {}, (data) => data.policy);

    const latestCommit = computed(
      () =>
        policy.value.repository && policy.value.repository.commits.edges[0].node
    );

    const dateString = computed(
      () => latestCommit.value && latestCommit.value.date
    );

    const lastModifiedDate = computed(() => getFormatDate(dateString.value));

    const resourceType = useResult(result, "", (data) => data.policy.resource);

    const commits = useResult(result, [], (data) => {
      const history = data.policy.history;
      return history.map((commitHistory) => ({
        ...commitHistory,
        dateFormatted: getFormatDate(commitHistory.commit.date),
      }));
    });

    const dateGroupCommits = computed(() => {
      return commits.value.reduce(
        (groups, item) => ({
          ...groups,
          [item.dateFormatted]: [...(groups[item.dateFormatted] || []), item],
        }),
        {}
      );
    });

    const resourceView = () => {
      state.tableViewPolicy = "resourceView";
      localStorage.setItem("tableViewPolicy", "resourceView");
    };
    const executionView = () => {
      state.tableViewPolicy = "executionView";
      localStorage.setItem("tableViewPolicy", "executionView");
    };

    const {
      policyResult,
      from,
      to,
      loading: loadingChart,
      error: errorChart,
      fetchMore,
    } = usePolicyMetrics({ name: policyName }, state.duration);

    const updateData = (timeline) => {
      state.duration = timeline;
    };
    watch(
      () => state.duration,
      async (duration) => {
        try {
          await fetchMore({
            variables: {
              from: getIsoUtcDateTimeFormatted(getFirstDateByPeriod(duration)),
              to,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousResult;
              return {
                ...previousResult,
                ...fetchMoreResult,
              };
            },
          });
        } catch (error) {
          if (error.name === "Invariant Violation") {
            // Swallowing Invariant Violation here to suppress errors when changing route
            // before `fetchMore` returns its Promise. Hopefully fixed in apollo client
            // at some point.
            // see: https://github.com/apollographql/apollo-client/issues/4114
          } else {
            throw error;
          }
        }
      }
    );

    const modalToggle = () => {
      state.showModal = !state.showModal;
    };

    return {
      state,
      loading,
      error,
      policyName,
      policy,
      resourceView,
      executionView,
      modalToggle,
      latestCommit,
      lastModifiedDate,
      resourceType,
      updateData,
      TimePeriod,
      from,
      loadingChart,
      errorChart,
      policyResult,
      dateGroupCommits,
    };
  },
};
</script>

<style lang="scss" scoped>
.st-metadata-body-wrapper {
  font-size: 14px;
}

.st-meta-data-item-wrapper {
  display: flex;
  flex-direction: row;
}

.changelog-button {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 38px;
  padding: 0 14px;
}

.metrics-wrapper {
  padding: 0;
  text-align: center;
}

.st-icon-changelog {
  margin-right: 8px;
}

.st-tab-selection.time-duration {
  .nav-item > a {
    height: 30px;
    padding: 0;
    width: 100px;
  }
}

.disabled {
  pointer-events: none;
}

.st-header-title {
  margin-top: 8px;
}

.centered {
  left: 50%;
  position: fixed;
  top: 50%;
}

.back-button {
  padding: 16px 0 8px;
}

.changelog {
  padding: 12px 0 20px;
}
</style>
