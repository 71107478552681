<template>
  <div id="chart" class="chart">
    <div id="chart-timeline">
      <apexchart
        height="400"
        type="area"
        :options="chartOptions"
        :series="[{ data: seriesValue }]"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "AreaChartWithDetail",
  props: {
    title: {
      type: String,
      required: true,
    },
    metricsApiData: {
      type: Array,
      required: true,
    },
    fromDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = {
      colors: ["#C05621"],
      title: {
        text: props.title,
        align: "left",
        margin: 50,
        style: {
          fontSize: "18px",
        },
      },
      chart: {
        id: props.title,
        background: "#fff",
        toolbar: {
          show: false,
        },
        zoom: {
          autoScaleYaxis: true,
        },
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter() {
              return "value:";
            },
          },
        },
      },
      yaxis: {
        show: true,
        opposite: true,
        decimalsInFloat: 2,
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        min: props.fromDate,
        max: new Date().getTime(),
      },
      grid: {
        show: true,
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
    };

    return {
      seriesValue: computed(() => {
        return props.metricsApiData.map((data) => {
          return [new Date(data.date).getTime(), data.value];
        });
      }),
      chartOptions,
    };
  },
};
</script>
<style lang="scss" scoped>
a {
  padding: 2px 10px;
}

.chart {
  background: $white;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.1);
  margin: 35px auto;
  max-width: 550px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-top: 20px;
}

@media (max-width: 992px) {
  .chart {
    max-width: 650px;
  }
}

.active {
  border-bottom: $st-orange solid 2px;
  color: $black;
  font-weight: bold;
}
</style>
