<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">Error: {{ error.message }}</div>
  <div v-else-if="executions" class="table-responsive">
    <table class="table table-sm small">
      <thead v-if="hasExecutions" class="table-light">
        <tr>
          <th class="fit">Execution ID</th>
          <th class="fit">Timestamp</th>
          <th class="fit">Provider</th>
          <th class="fit">Account ID</th>
          <th class="fit">Region</th>
          <th class="fit">Execution Time</th>
          <th class="fit">Resources</th>
          <th class="fit">API Calls</th>
          <th class="fit">Avg API Rate</th>
          <th class="fit">Raised Exception</th>
        </tr>
      </thead>
      <tbody v-if="hasExecutions">
        <tr
          v-for="(execution, index) in executions"
          :key="index"
          class="st-table-hover"
          role="button"
          @click="linkTo(execution.uuid)"
          @keypress="linkTo(execution.uuid)"
        >
          <td data-testid="execution-uuid">{{ execution.uuid }}</td>
          <td data-testid="execution-start-time">
            {{ getUTCDateTime(execution.start) }}
          </td>
          <td data-testid="execution-account-provider">
            {{ execution.account.provider }}
          </td>
          <td data-testid="execution-account-id">{{ execution.account.id }}</td>
          <td data-testid="execution-param-region">
            {{ execution.paramRegion }}
          </td>
          <td data-testid="execution-time">{{ execution.executionTime }}</td>
          <td data-testid="execution-metric-resources">
            {{ execution.metricResources }}
          </td>
          <td data-testid="execution-metric-api-calls">
            {{ execution.metricApiCalls }}
          </td>
          <td data-testid="execution-avg-api-rate">
            {{ execution.avgApiRate }}
          </td>

          <td>No</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>No executions</td>
        </tr>
      </tbody>
    </table>
    <PaginationApi
      v-if="hasExecutions"
      :page-size="state.pageSize"
      :handle-page-size-change="handlePageSizeChange"
      :has-previous-page="hasPreviousPage"
      :has-next-page="hasNextPage"
      :load-more="loadMore"
    />
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";

import useLoadMore from "@/composables/useLoadMore";
import allExecutionsQuery from "@/graphql/allExecutions.query.gql";
import {
  getTimeMillis,
  millisToTime,
  getUTCDateTime,
} from "@/utils/datetime.js";

import PaginationApi from "./PaginationApi.vue";

export default {
  name: "ExecutionList",
  components: { PaginationApi },
  props: {
    query: {
      type: String,
      default: "",
    },
    policyId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      pageSize: 10,
      searchQuery: computed(() => props.query),
    });
    const { result, loading, error, fetchMore } = useQuery(
      allExecutionsQuery,
      {
        policyUUID: computed(() => props.policyId),
        first: computed(() => parseInt(state.pageSize)),
        last: computed(() => parseInt(state.pageSize)),
        after: "",
        before: "",
      },
      { notifyOnNetworkStatusChange: true }
    );

    const executions = useResult(result, [], (data) => {
      const rawExecutions = data.executions.edges.map((edge) => edge.node);

      return rawExecutions.map((execution) => {
        const newExecution = { ...execution };
        const startTime = getTimeMillis(execution.start);
        const endTime = getTimeMillis(execution.end);
        const executionTimeInMilliSec = endTime - startTime;
        const executionTimeInSec = executionTimeInMilliSec / 1000;
        newExecution.avgApiRate = (
          execution.metricApiCalls / executionTimeInSec
        ).toFixed(2);
        newExecution.resourcePerCall = (
          execution.metricResources / execution.metricApiCalls
        ).toFixed(2);
        newExecution.executionTime = millisToTime(executionTimeInMilliSec);
        return newExecution;
      });
    });

    // TODO: [STKLT-771] write test for no executions state
    const hasExecutions = computed(() => executions.value.length > 0);

    const pageInfo = useResult(
      result,
      { hasPreviousPage: false, hasNextPage: false },
      (data) => data.executions.pageInfo
    );

    const { hasPreviousPage, hasNextPage, loadMore } = useLoadMore(
      fetchMore,
      pageInfo
    );

    const handlePageSizeChange = (pageSize) => {
      if (pageSize) {
        state.pageSize = pageSize;
      }
    };

    const linkTo = (executionID) => {
      router.push({
        name: "ExecutionDetail",
        params: { executionID },
      });
    };
    return {
      state,
      linkTo,
      executions,
      hasExecutions,
      loading,
      error,
      loadMore,
      handlePageSizeChange,
      hasPreviousPage,
      hasNextPage,
      getUTCDateTime,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1800px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
