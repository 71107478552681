<template>
  <div class="card-wrapper">
    <div class="card card-title-wrapper">
      <div class="card-title d-flex justify-content-between">
        <div class="card-text d-flex justify-content-between">
          <span class="st-icon st-icon-git-commit mr-3"></span>
          <div>{{ dateGroup }}</div>
        </div>
        <div class="card-text">{{ commitsData.length }} Commit</div>
      </div>
    </div>
    <div class="card-body-wrapper">
      <!-- <div class="vl"></div> -->
      <ChangelogCard
        v-for="(commit, index) in commitsData"
        :key="index"
        :commit-data="commit"
      />
    </div>
  </div>
</template>

<script>
import ChangelogCard from "./ChangelogCard.vue";
export default {
  name: "ChangelogDateGroup",
  components: { ChangelogCard },
  props: {
    commitsData: {
      type: Array,
      required: true,
    },
    dateGroup: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.vl {
  border-left: 1px solid #d3d3d3;
  height: 100%;
  left: 45px;
  margin-left: -3px;
  position: absolute;
  top: 23px;
}

.card-title-wrapper {
  background-color: $st-cloud;
  margin-bottom: 50px;

  .card-title {
    margin-bottom: 0;
    padding: 8px 32px;
  }

  .card-text {
    font-size: 14px;
  }

  .card-text > .s-icon {
    width: 14px;
  }
}

.card-body-wrapper {
  padding: 0 16px 48px;
}

.st-icon {
  z-index: 3;
}
</style>
